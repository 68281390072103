<header id="banner" class="scrollto clearfix" data-enllax-ratio=".5">
  <!--Banner Content-->
  <div id="banner-content" class="row clearfix">

    <div class="col-38">

      <div class="section-heading">
        <h1>Agricultural, industrial and chemical supplies</h1>
        <h2>With our vast network of partners, we source and supply at the most competitive price</h2>
      </div>
    </div>

  </div>
  <!--End of Row-->
</header>
