<!--Content Section-->
<div id="services" class="scrollto clearfix">

  <div class="row no-padding-bottom clearfix">


    <!--Content Left Side-->
    <div class="col-3">
      <!--User Testimonial-->
      <blockquote class="testimonial text-right bigtest">
        <q>Excellence, growth and simplicity</q>
        <footer>— Mission</footer>
      </blockquote>
      <!-- End of Testimonial-->

    </div>
    <!--End Content Left Side-->

    <!--Content of the Right Side-->
    <div class="col-3">
      <div class="section-heading">
        <h3>SEED AND GRAIN</h3>
        <p class="section-subtitle">Premium certified quality seeds and seedlings for grains, vegetables, fruit, forage and trees. We also cater for bulk grain and finished derivates from agriculture.
          requirements. </p>
      </div>
      <div class="section-heading">
        <h3>FRUIT AND VEGETABLES</h3>
        <p class="section-subtitle">Fresh, dried or frozen fruit and vegetables packed to your specification</p>
      </div>
      <div class="section-heading">
        <h3>FARM MECHANISATION AND TECHNOLOGY</h3>
        <p class="section-subtitle">Mechanical equipment, solar products, hardware tools; monitoring and control systems.</p>
      </div>
      <div class="section-heading">
        <h3>CROP AND LIVESTOCK NUTRITION</h3>
        <p class="section-subtitle">Fertilisers, stockfeed and chemicals from renowned manufacturers.
      </div>
      <div class="section-heading">
        <h3>CROP AND ANIMAL PROTECTION</h3>
        <p class="section-subtitle">Animal and plant care products</p>
      </div>
      <div class="section-heading">
        <h3>ENVIRONMENTAL AND SECURITY PRODUCTS</h3>
        <p class="section-subtitle">Workwear and PPE, Water and sewer treatment, Fencing
          solutions</p>
      </div>
      <div class="section-heading">
        <h3>PACKAGING AND LOGISTICS</h3>
        <p class="section-subtitle">Packaging and handling services</p>
      </div>

    </div>
    <!--End Content Right Side-->

    <div class="col-4">
      <img src="../assets/images/nua.jpg" alt="Dancer" />
    </div>
    <div class="col-4">
      <img src="../assets/images/tomato.jpg" alt="Dancer" />
    </div>
   

  </div>


</div>
<!--End of Content Section-->