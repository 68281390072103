<div class="page-border" data-wow-duration="0.7s" data-wow-delay="0.2s">
  <div class="top-border wow fadeInDown animated" style="visibility: visible; animation-name: fadeInDown;"></div>
  <div class="right-border wow fadeInRight animated" style="visibility: visible; animation-name: fadeInRight;"></div>
  <div class="bottom-border wow fadeInUp animated" style="visibility: visible; animation-name: fadeInUp;"></div>
  <div class="left-border wow fadeInLeft animated" style="visibility: visible; animation-name: fadeInLeft;"></div>
</div>
<!--Main Content Area-->
<main id="content">

    <app-navigation></app-navigation>
  <router-outlet></router-outlet>
</main>
<!--End Main Content Area-->
