<aside>
  <!--Social Icons in Header--
  <ul class="social-icons">
    <li>
      /
      <a target="_blank" title="Facebook" href="https://www.facebook.com/username">
        <i class="fa fa-facebook fa-1x"></i><span>Facebook</span>
      </a>
    </li>
  </ul>
  --End of Social Icons in Header-->
</aside>