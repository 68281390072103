<!--Introduction-->
<section id="about" class="introduction scrollto">

  <div class="row clearfix">
    <div class="col-3">
      <div class="section-heading">
        <h2 class="section-title">WHO WE ARE</h2>
        <p class="section-subtitle">Inchway Pty limited is an organisation that is ready to assist in the
          smooth flow of your value chain. We aim to provide top-class and comprehensive solutions to our customers, 
          through sourcing and innovation. Our markets amongs others, spans across NGO's, manufacturing companies,
          government departments, municipalities and agricultural holdings.</p>
      </div>
    </div>

    <div class="col-2-3">

      <!--Icon Block-->
      <div class="col-2 icon-block icon-top wow fadeInUp" data-wow-delay="0.1s">
        <!--Icon-->
        <div class="icon">
          <i class="fa fa-bolt fa-2x"></i>
        </div>
        <!--Icon Block Description-->
        <div class="icon-block-description">
          <h3>VISION</h3>
          <p>Our mission is to be the preferred partner in leveraging the customer to achive efficiency, growth and
            simplicity in their operations.</p>
        </div>
      </div>
      <!--End of Icon Block-->
      <!--Icon Block-->
      <div class="col-2 icon-block icon-top wow fadeInUp" data-wow-delay="0.3s">
        <!--Icon-->
        <div class="icon">
          <i class="fa fa-bolt fa-2x"></i>
        </div>
        <!--Icon Block Description-->
        <div class="icon-block-description">
          <h3>VALUES</h3>
          <p>Our core valus are:
            Respect, Integrity, Responsibility and Teamwork </p>
        </div>
      </div>
      <!--End of Icon Block-->
      <!--Icon Block-->
      <div class="col-2 icon-block icon-top wow fadeInUp" data-wow-delay="0.5s">
        <!--Icon-->
        <div class="icon">
          <i class="fa fa-bolt fa-2x"></i>
        </div>
        <!--Icon Block Description-->
        <div class="icon-block-description">
          <h3>MISSION</h3>
          <p>Provide high quality products to our customers</p>
          <p>Deliver in time and within budget</p>
          <p>Monitor and be responsive to the changing needs of our customers</p>
          <p>Continuously improve the quality of service to our customers</p>
          <p>Create and nature long term relationships</p>
        </div>
      </div>
      <!--End of Icon Block-->

    </div>

  </div>


</section>
<!--End of Introduction-->