<!--Footer-->
<footer id="landing-footer" class="clearfix">
  <div class="row clearfix">

    
    <!--Social Icons in Footer-->
    <div class="col-2 social-icons" id="footer-social app-social">
      <app-social></app-social>
    </div>
    <!--End of Social Icons in Footer-->
  </div>
</footer>
<!--End of Footer-->