<!--Pricing Tables-->
<section id="pricing" class="secondary-color text-center scrollto clearfix ">
  <div class="row clearfix">

    <div class="section-heading">
      <h3>We are at your service</h3>
    </div>
    <div class="pricing-block featured col-3 wow fadeInUp" data-wow-delay="0.6s">

    </div>
    <!--Pricing Block-->
    <div class="pricing-block col-3 wow fadeInUp" >
      <div class="pricing-block-content">
        <h3>Contact Details</h3>
        <ul>
          <li>42 Etude, 47 Mozart Lane</li>
          <li>Sagewood</li>
          <li>Midrand, 1686</li>
          <li>+27 60 525 0438</li>
          <li>South Africa</li>
          <li>sales@inchway.co.za</li>
        </ul>
      </div>
    </div>
    <!--End Pricing Block-->
 <!--Pricing Block-->
 <div class="pricing-block featured col-3 wow fadeInUp" data-wow-delay="0.6s">
  
</div>
<!--End Pricing Block-->

  

  </div>
</section>
<!--End of Pricing Tables-->